import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '@environments/environment';
import { ScreenService } from '@utils/screen';
import { AuthService, NavigatorService } from '@services/security';

import { RouteCategory, SidebarRoute } from './interfaces/route.interface';
import { SidebarState } from './types/sidebar-state.enum';
import { SidebarService } from './services/sidebar.service';
import { kSidebarRoutes } from './sidebar.routes';

@UntilDestroy()
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    readonly SidebarState = SidebarState;
    readonly RouteCategory = RouteCategory;
    user: any;
    theme: string;
    routes: SidebarRoute[];
    state$ = this.sidebarService.state$;

    constructor(
        private router: Router,
        private screenService: ScreenService,
        public authService: AuthService,
        private navigatorService: NavigatorService,
        private sidebarService: SidebarService,
    ) {}

    public ngOnInit(): void {
        this.initUser();
        this.onRouteChanged();
    }

    toggle(): void {
        this.sidebarService.toggle();
    }

    logout(): void {
        this.authService.logout();
    }

    changeAccount(): void {
        window.location.href = `${environment.authfrontURL}/pscAuth`;
    }

    private initUser(): void {
        this.authService.currentUser
            .pipe(
                tap((user) => {
                    this.user = user;
                    this.theme = this.sidebarService.getUserTheme(user.type);
                    this.initSidebar();
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private initSidebar() {
        this.navigatorService
            .getRoutes()
            .pipe(
                tap((accessRoutes) => {
                    this.routes = this.filterRoutes(accessRoutes);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }
    // accessRoutes look like ["alertsstats","alerts","alertstechnical","patients"...]
    private filterRoutes(accessRoutes: string[]) {
        return kSidebarRoutes.map((routes) => {
            const links = routes.links.filter((link) =>
                accessRoutes.includes(link.label),
            );

            return {
                ...routes,
                links,
            };
        });
    }

    private onRouteChanged(): void {
        this.router.events
            .pipe(
                filter((event) => !!(event instanceof NavigationEnd)),
                tap(() => {
                    // mobile & tablet we close the sidebar after page changes.
                    if (
                        !this.screenService.desktop &&
                        (this.sidebarService.state === SidebarState.OPEN ||
                            this.sidebarService.state === SidebarState.HOVER)
                    ) {
                        this.toggle();
                    }
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    gotoTuto() {
        if (this.authService.isCardio) {
            window.open(environment.tutoCardio);
        } else if (this.authService.isNephro) {
            window.open(environment.tutoNephro);
        }
    }
}
