import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-filter-regions',
    templateUrl: './filter-regions.component.svg',
    styleUrls: ['./filter-regions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterRegionsComponent {
    @Input() disabled = true;
}
