<div class="mobile-banner">
    <button
        (click)="bsModalRef?.hide()"
        aria-label="Close"
        class="btn m-3 p-2"
        type="button"
    >
        <app-close active="true"></app-close>
    </button>
    <div class="d-flex flex-column">
        <span translate="inclusion.mobileTitle"></span>
        <span class="subTitle" translate="inclusion.mobileSubTitle"></span>
    </div>
</div>
<div class="p-2 potential-patient-container">
    <div *ngIf="potentialPatient" class="potentiel-patient-card">
        <div class="d-md-none">
            <span class="d-flex notif">
                <p>
                    {{
                        'inclusion.present'
                            | translate
                                : {
                                      inclusionDate:
                                          potentialPatient.createdAt
                                          | date : 'dd/MM/YYYY'
                                  }
                    }}
                </p>
                <p class="ps-1">
                    {{
                        potentialPatient?.firstName +
                            ' ' +
                            potentialPatient?.lastName
                    }}
                </p>
            </span>
        </div>
        <app-info class="d-md-flex d-none"></app-info>
        <span class="d-md-flex d-none notif">
            <p>
                {{
                    'inclusion.present'
                        | translate
                            : {
                                  inclusionDate:
                                      potentialPatient.createdAt
                                      | date : 'dd/MM/YYYY'
                              }
                }}
            </p>
            <p class="ps-1">
                {{
                    potentialPatient?.firstName +
                        ' ' +
                        potentialPatient?.lastName
                }}
            </p>
        </span>
        <button
            class="btn btn-primary height-50 ms-auto"
            translate="inclusion.presentLink"
            (click)="goToPotentialPatient()"
        ></button>
    </div>
</div>
<div *ngIf="patient" class="d-flex flex-column wrapper">
    <div class="d-flex flex-md-row flex-column justify-content-between">
        <div class="d-flex flex-column">
            <div class="inclusion-title" translate="inclusion.title">
                <span class="common-law" translate="inclusion.commonLaw"></span>
                <img
                    src="assets/image/info2.svg"
                    alt="vector"
                    class="ps-2 icon-info"
                    (click)="openModal(template)"
                />
            </div>
            <div class="d-flex flex-column flex-md-row">
                <div class="criteria-mobile">
                    <div class="criteria-bull">
                        <li
                            translate="inclusion.inclusionMode.cardio.criteria1"
                        ></li>
                        <div translate="or"></div>
                        <li
                            translate="inclusion.inclusionMode.cardio.criteria2"
                        ></li>
                    </div>
                </div>
                <div class="flex p-2">
                    <label
                        class="title mr-2"
                        translate="profile.configuration.devices.title"
                    ></label>
                    <div class="form-check mr-2">
                        <input
                            [(ngModel)]="patient.inclusion.enableScale"
                            disabled="true"
                            class="form-check-input"
                            id="enableScale"
                            type="checkbox"
                        />
                        <label
                            class="form-check-label"
                            for="enableScale"
                            translate="devices.type.scale"
                        ></label>
                    </div>
                    <div class="form-check">
                        <input
                            [checked]="patient.inclusion.enableTensio"
                            (click)="tensioUpdated($event)"
                            class="form-check-input"
                            title="{{ 'devices.info' | translate }}"
                            id="enableTensio"
                            type="checkbox"
                        />
                        <label
                            class="form-check-label"
                            for="enableTensio"
                            translate="devices.type.tensiometer"
                        ></label>
                    </div>
                </div>
                <div
                    *ngIf="authService.isAdminMedical"
                    class="d-flex flex-row p-2"
                >
                    <input
                        [(ngModel)]="patient.stranger"
                        class="form-check-input"
                        type="checkbox"
                        id="stranger"
                    />
                    <label
                        class="form-check-label ms-1"
                        for="stranger"
                        translate="inclusion.identity.stranger"
                    ></label>
                </div>
            </div>
        </div>

        <button
            (click)="bsModalRef?.hide()"
            aria-label="Close"
            class="btn-close close"
            type="button"
        ></button>
    </div>
    <div class="d-flex flex-column flex-xxl-row gap-4 gap-xxl-3">
        <div class="d-flex flex-column justify-content-between gap-4">
            <div class="form-section">
                <div class="form-section-title">
                    <app-address-card
                        class="form-section-icon"
                    ></app-address-card>
                    <span
                        translate="inclusion.inclusionMode.cardio.identity"
                    ></span>
                </div>
                <div>
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-column flex-lg-row">
                            <div class="flex-column p-2">
                                <input
                                    (change)="removeError('lastName')"
                                    [(ngModel)]="patient.lastName"
                                    [class.is-invalid]="hasError('lastName')"
                                    [placeholder]="
                                        'inclusion.identity.lastName'
                                            | translate
                                    "
                                    autocomplete="nope"
                                    class="form-control input-width"
                                    type="text"
                                />
                                <div
                                    class="invalid-feedback"
                                    translate="errors.patient.INCORRECT_LASTNAME"
                                ></div>
                            </div>
                            <div class="flex-column p-2">
                                <input
                                    (change)="
                                        oncheckPatientExists(
                                            $event,
                                            'firstName'
                                        )
                                    "
                                    [(ngModel)]="patient.firstName"
                                    [class.is-invalid]="hasError('firstName')"
                                    [placeholder]="
                                        'inclusion.identity.firstName'
                                            | translate
                                    "
                                    autocomplete="nope"
                                    class="form-control input-width"
                                    type="text"
                                />
                                <div
                                    class="invalid-feedback"
                                    translate="errors.patient.EMPTY_FIRSTNAME"
                                ></div>
                            </div>
                            <div class="flex-column p-2">
                                <input
                                    [(ngModel)]="patient.birthLastName"
                                    [placeholder]="
                                        'inclusion.identity.birthLastName'
                                            | translate
                                    "
                                    autocomplete="nope"
                                    class="form-control input-width"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-lg-row">
                            <div class="flex-column p-2">
                                <div
                                    [class.is-invalid]="hasError('sex')"
                                    (change)="removeError('sex')"
                                    class="btn-group input-width"
                                    role="group"
                                >
                                    <input
                                        [(ngModel)]="patient.sex"
                                        autocomplbtn-checkete="off"
                                        class="btn-check"
                                        id="male"
                                        name="sex"
                                        type="radio"
                                        value="male"
                                    />
                                    <label
                                        [class.is-invalid]="hasError('sex')"
                                        class="btn btn-outline-primary"
                                        for="male"
                                        translate="inclusion.sex.male"
                                    ></label>
                                    <input
                                        [(ngModel)]="patient.sex"
                                        autocomplete="off"
                                        class="btn-check"
                                        id="female"
                                        name="sex"
                                        type="radio"
                                        value="female"
                                    />
                                    <label
                                        [class.is-invalid]="hasError('sex')"
                                        class="btn btn-outline-primary"
                                        for="female"
                                        translate="inclusion.sex.female"
                                    ></label>

                                    <input
                                        [(ngModel)]="patient.sex"
                                        autocomplete="off"
                                        class="btn-check"
                                        id="undetermined"
                                        name="sex"
                                        type="radio"
                                        value="undetermined"
                                    />
                                    <label
                                        [class.is-invalid]="hasError('sex')"
                                        class="btn btn-outline-primary"
                                        for="undetermined"
                                        translate="inclusion.sex.undetermined"
                                    ></label>
                                </div>
                                <div
                                    *ngIf="hasFeedbackError('EMPTY_SEX')"
                                    class="invalid-feedback"
                                    translate="errors.patient.EMPTY_SEX"
                                ></div>
                            </div>
                            <div class="flex-column p-2">
                                <input
                                    (bsValueChange)="onDateChange($event)"
                                    [bsConfig]="{
                                        isAnimated: true,
                                        dateInputFormat: 'DD/MM/YYYY',
                                        showWeekNumbers: false
                                    }"
                                    [class.is-invalid]="hasError('birthDate')"
                                    autocomplete="off"
                                    bsDatepicker
                                    class="form-control input-width"
                                    id="birthdate"
                                    type="text"
                                    [placeholder]="
                                        ('inclusion.identity.birthdate'
                                            | translate) +
                                        (authService.isAdmin ? '' : '*')
                                    "
                                />
                                <div
                                    *ngIf="
                                        hasFeedbackError('MISSING_BIRTHDATE')
                                    "
                                    class="invalid-feedback"
                                    translate="errors.patient.MISSING_BIRTHDATE"
                                ></div>
                                <div
                                    *ngIf="
                                        hasFeedbackError('INVALID_BIRTHDATE')
                                    "
                                    class="invalid-feedback"
                                    translate="errors.patient.INVALID_BIRTHDATE"
                                ></div>
                            </div>
                            <div class="flex-column p-2">
                                <input
                                    (change)="
                                        oncheckPatientExists(
                                            $event,
                                            'socialSecurityNumber'
                                        )
                                    "
                                    [(ngModel)]="patient.socialSecurityNumber"
                                    [class.is-invalid]="
                                        hasError('socialSecurityNumber')
                                    "
                                    [placeholder]="
                                        ('inclusion.identity.socialSecurityNumber'
                                            | translate) +
                                        (authService.isAdmin ? '' : '*')
                                    "
                                    autocomplete="off"
                                    class="form-control input-width"
                                    id="socialSecurityNumber"
                                    type="text"
                                />
                                <div
                                    class="invalid-feedback"
                                    translate="errors.patient.INVALID_SSNUM"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-md-row p-2">
                        <select
                            class="form-control form-select input-width"
                            id="identityDoc"
                            (change)="removeError('identityDoc')"
                            [(ngModel)]="patient.identityDoc"
                            [disabled]="patient.identityDocConfirmation"
                            [class.is-invalid]="hasError('identityDoc')"
                        >
                            <option [value]="undefined" selected disabled>
                                {{
                                    ('inclusion.identity.identityVerifiedBy'
                                        | translate) +
                                        (authService.isAdmin ? '' : '*')
                                }}
                            </option>
                            <option
                                *ngFor="
                                    let identityDocOption of identityDocOptions
                                "
                                [value]="identityDocOption.key"
                            >
                                {{ identityDocOption.labelFr }} -
                                <span
                                    class="sub-text"
                                    *ngIf="
                                        identityDocOption.trustLevel === 'high'
                                    "
                                    >Haut niveau de confiance</span
                                >
                                <span
                                    class="sub-text"
                                    *ngIf="
                                        identityDocOption.trustLevel !== 'high'
                                    "
                                    >Bas niveau de confiance</span
                                >
                            </option>
                        </select>
                        <div class="p-2">
                            <span translate="or" class="p-2"></span>
                            <input
                                [(ngModel)]="patient.identityDocConfirmation"
                                (change)="onNoVerifiedIdChange($event)"
                                class="form-check-input"
                                id="noVerifiedId"
                                type="checkbox"
                            />
                            <label
                                class="form-check-label ps-1"
                                for="noVerifiedId"
                                translate="inclusion.identity.noVerifiedId"
                            ></label>
                            <div
                                *ngIf="
                                    hasFeedbackError('UNCONFIRMED_IDENTITYDOC')
                                "
                                class="invalid-feedback"
                                translate="errors.patient.UNCONFIRMED_IDENTITYDOC"
                            ></div>
                        </div>
                    </div>
                    <div class="d-flex flex-raw justify-content-start p-2">
                        <input
                            (change)="removeError('inclusion.signedConsent')"
                            [(ngModel)]="patient.inclusion.signedConsent"
                            [class.is-invalid]="
                                hasError('inclusion.signedConsent')
                            "
                            class="form-check-input"
                            id="signedContent"
                            type="checkbox"
                        />
                        <label
                            class="form-check-label ms-1"
                            for="signedContent"
                            translate="inclusion.inclusionMode.signedContent"
                        ></label
                        >*
                        <div class="form-check">
                            <div
                                class="invalid-feedback"
                                translate="errors.patient.MEDATA_SIGNED_CONTENT_SHOULD_BE_TRUE"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <div class="form-section-title">
                    <app-stethoscope
                        class="form-section-icon"
                    ></app-stethoscope>
                    <span translate="inclusion.medicalData.medicalData"></span>
                </div>
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column flex-lg-row">
                        <div class="flex-column p-2">
                            <select
                                class="form-control form-select input-width"
                                [(ngModel)]="patient.medicalData.currentRate"
                                (change)="
                                    removeError('medicalData.currentRate')
                                "
                                [class.is-invalid]="
                                    hasError('medicalData.currentRate')
                                "
                            >
                                <option [value]="undefined" selected disabled>
                                    {{
                                        ('inclusion.medicalData.currentRate'
                                            | translate) +
                                            (authService.isAdmin ? '' : '*')
                                    }}
                                </option>
                                <option
                                    value="sinusal"
                                    translate="inclusion.currentRate.sinusal"
                                ></option>
                                <option
                                    value="fa"
                                    translate="inclusion.currentRate.fa"
                                ></option>
                                <option
                                    value="sv_trouble"
                                    translate="inclusion.currentRate.sv_trouble"
                                ></option>
                            </select>
                            <div
                                *ngIf="
                                    hasFeedbackError(
                                        'INVALID_MEDATA_CURRENT_RATE'
                                    )
                                "
                                class="error"
                                translate="errors.patient.INVALID_MEDATA_CURRENT_RATE"
                            ></div>
                        </div>
                        <div class="flex-column p-2">
                            <select
                                class="form-control form-select input-width"
                                [(ngModel)]="patient.medicalData.stimulator"
                                (change)="removeError('medicalData.stimulator')"
                                [class.is-invalid]="
                                    hasError('medicalData.stimulator')
                                "
                            >
                                <option [value]="undefined" selected disabled>
                                    {{
                                        ('inclusion.medicalData.stimulator'
                                            | translate) +
                                            (authService.isAdmin ? '' : '*')
                                    }}
                                </option>
                                <option
                                    value="no"
                                    translate="inclusion.stimulator.no"
                                ></option>
                                <option
                                    value="defibrillator"
                                    translate="inclusion.stimulator.defibrillator"
                                ></option>
                                <option
                                    value="pacemaker"
                                    translate="inclusion.stimulator.pacemaker"
                                ></option>
                                <option
                                    value="lifevest"
                                    translate="inclusion.stimulator.lifevest"
                                ></option>
                            </select>
                            <div
                                *ngIf="
                                    hasFeedbackError(
                                        'INVALID_MEDATA_STIMULATOR'
                                    )
                                "
                                class="error"
                                translate="errors.patient.INVALID_MEDATA_STIMULATOR"
                            ></div>
                        </div>
                        <div class="flex-column p-2">
                            <div class="d-flex flex-row">
                                <select
                                    class="form-control form-select input-width-package"
                                    [(ngModel)]="
                                        patient.inclusion.billingPackage
                                    "
                                    id="type"
                                >
                                    <option
                                        translate="ui.package.title"
                                        [value]="undefined"
                                        selected
                                        disabled
                                    ></option>
                                    <option
                                        translate="ui.package.tvb"
                                        value="tvb"
                                    ></option>
                                    <option
                                        translate="ui.package.tvb2"
                                        value="tvb2"
                                    ></option>
                                    <option
                                        translate="ui.package.tvb25"
                                        value="tvb25"
                                    ></option>
                                </select>
                                <app-package-info
                                    class="ms-2"
                                ></app-package-info>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-lg-row">
                        <div class="flex-column p-2">
                            <input
                                (change)="removeError('medicalData.initWeight')"
                                [(ngModel)]="patient.medicalData.initWeight"
                                [class.is-invalid]="
                                    hasError('medicalData.initWeight')
                                "
                                [placeholder]="
                                    ('inclusion.medicalData.initWeight'
                                        | translate) +
                                    (authService.isAdmin ? '' : '*')
                                "
                                autocomplete="off"
                                class="form-control input-width"
                                id="initWeight"
                                type="number"
                                min="0"
                                max="250"
                            />
                            <div
                                class="invalid-feedback"
                                translate="errors.patient.INVALID_INITWEIGHT"
                            ></div>
                        </div>
                        <div class="flex-column p-2">
                            <input
                                (change)="
                                    removeError('medicalData.systolicFunction')
                                "
                                [(ngModel)]="
                                    patient.medicalData.systolicFunction
                                "
                                [class.is-invalid]="
                                    hasError('medicalData.systolicFunction')
                                "
                                [placeholder]="
                                    'inclusion.medicalData.systolicFunction'
                                        | translate
                                "
                                autocomplete="off"
                                class="form-control input-width"
                                id="systolicFunction"
                                type="number"
                            />
                            <div
                                class="invalid-feedback"
                                translate="errors.patient.INVALID_MEDATA_SYSTOLIC_FUNCTION"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section min-ordo-height">
                <div class="form-section-title">
                    <app-file-signature
                        class="form-section-icon"
                    ></app-file-signature>
                    <span
                        translate="inclusion.inclusionMode.cardio.ordo"
                    ></span>
                </div>
                <div class="d-flex flex-column">
                    <div *ngIf="authService.isAdmin" class="p-2">
                        <input
                            (change)="selectCardio($event)"
                            [class.is-invalid]="hasError('specialistId')"
                            [defaultValue]="findCardio(patient.specialistId)"
                            [placeholder]="
                                ('inclusion.carestaff.careStaffAccount'
                                    | translate) + '*'
                            "
                            class="form-control input-width"
                            id="cardio"
                            list="cardios"
                        />
                        <datalist id="cardios">
                            <option *ngFor="let cardio of careStaffs?.cardio">
                                {{ displayName(cardio) }}
                            </option>
                        </datalist>
                        <div
                            class="invalid-feedback"
                            translate="errors.patient.MISSING_CARDIOLOGIST"
                        ></div>
                    </div>
                    <div
                        *ngIf="checkModalTemplate()"
                        class="d-flex flex-row align-items-center"
                    >
                        <input
                            (change)="removeError('inclusion.modalTemplate')"
                            [(ngModel)]="patient.inclusion.modalTemplate"
                            [class.is-invalid]="
                                hasError('inclusion.modalTemplate')
                            "
                            class="form-check-input m-2"
                            id="modalTemplate"
                            type="checkbox"
                        />
                        <label class="form-check-label" for="modalTemplate"
                            >{{
                                'inclusion.inclusionMode.modalTemplate'
                                    | translate
                                        : { date: presciptionDateString }
                            }}
                        </label>
                        <div
                            class="invalid-feedback"
                            translate="errors.patient.MISSING_CARDIOLOGIST_MODEL_TEMPLATE"
                        ></div>
                    </div>
                    <div class="d-flex flex-column flex-md-row">
                        <ng-container *ngIf="!patient.inclusion.modalTemplate">
                            <ngx-dropzone
                                (change)="
                                    onSelectPrescription($event.addedFiles[0])
                                "
                                id="prescription"
                                multiple="false"
                                class="m-2"
                            >
                                <ngx-dropzone-label
                                    *ngIf="!patient.prescriptionFileName"
                                >
                                    <img
                                        src="../../../../assets/image/upload.svg"
                                    />
                                    <div
                                        class="first-text"
                                        translate="inclusion.inclusionMode.dropzoneFast"
                                    ></div>
                                    <div
                                        translate="inclusion.inclusionMode.dropzoneFast2"
                                    ></div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-label
                                    *ngIf="patient.prescriptionFileName"
                                >
                                    <span>{{
                                        patient.prescriptionFileName
                                    }}</span>
                                    <img
                                        (click)="onRemovePrescription($event)"
                                        class="ms-2"
                                        src="../../../../assets/image/trash.svg"
                                    />
                                </ngx-dropzone-label>
                            </ngx-dropzone>
                        </ng-container>
                        <div
                            *ngIf="
                                currentUser?.sharedProfile ||
                                patient.specialistId?.sharedProfile
                            "
                            class="d-flex flex-column justify-content-between p-2"
                        >
                            <input
                                (change)="removeError('prescriber.lastname')"
                                [(ngModel)]="patient.prescriber.lastname"
                                [class.is-invalid]="
                                    hasError('prescriber.lastname')
                                "
                                autocomplete="off"
                                class="form-control input-width"
                                [placeholder]="
                                    ('inclusion.carestaff.lastname'
                                        | translate) + '*'
                                "
                                id="prescriber.lastname"
                                type="text"
                            />
                            <div
                                class="invalid-feedback"
                                translate="errors.patient.MISSING_CARDIOLOGIST_PRESCRIBER_LASTNAME"
                            ></div>
                            <input
                                (change)="removeError('prescriber.firstname')"
                                [(ngModel)]="patient.prescriber.firstname"
                                [class.is-invalid]="
                                    hasError('prescriber.firstname')
                                "
                                [placeholder]="
                                    ('inclusion.carestaff.firstname'
                                        | translate) + '*'
                                "
                                autocomplete="off"
                                class="form-control input-width mt-2"
                                id="prescriber.firstname"
                                type="text"
                            />
                            <div
                                class="invalid-feedback"
                                translate="errors.patient.MISSING_CARDIOLOGIST_PRESCRIBER_FIRSTNAME"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="d-flex flex-column flex-md-row flex-xxl-column align-items-stretch align-items-xxl-center justify-content-between gap-4"
        >
            <div class="form-section">
                <div class="form-section-title">
                    <app-phone class="form-section-icon"></app-phone>
                    <span
                        translate="inclusion.inclusionMode.cardio.contact"
                    ></span>
                </div>
                <div class="d-flex flex-column">
                    <div class="flex-column p-2">
                        <input
                            (change)="removeError('phones.first')"
                            [(ngModel)]="patient.phones.first"
                            [class.is-invalid]="hasError('phones.first')"
                            [placeholder]="
                                ('inclusion.contact.phone' | translate) + ' 1*'
                            "
                            autocomplete="off"
                            class="form-control input-width"
                            id="phones.first"
                            type="search"
                        />
                        <div
                            *ngIf="hasFeedbackError('ONE_PHONE_REQUIRED')"
                            class="invalid-feedback"
                            translate="errors.patient.ONE_PHONE_REQUIRED"
                        ></div>
                        <div
                            *ngIf="hasFeedbackError('PHONE_FORMAT')"
                            class="invalid-feedback"
                            translate="errors.patient.PHONE_FORMAT"
                        ></div>
                    </div>
                    <div class="flex-column p-2">
                        <input
                            (change)="removeError('phones.second')"
                            [(ngModel)]="patient.phones.second"
                            [class.is-invalid]="hasError('phones.second')"
                            [placeholder]="
                                ('inclusion.contact.phone' | translate) + ' 2'
                            "
                            autocomplete="off"
                            class="form-control input-width"
                            id="phones.second"
                            type="search"
                        />
                        <div
                            *ngIf="hasFeedbackError('PHONE2_FORMAT')"
                            class="invalid-feedback"
                            translate="errors.patient.PHONE2_FORMAT"
                        ></div>
                    </div>
                    <div class="flex-column p-2">
                        <input
                            (change)="removeError('email')"
                            [(ngModel)]="patient.email"
                            [placeholder]="'inclusion.contact.mail' | translate"
                            [class.is-invalid]="hasError('email')"
                            autocomplete="off"
                            class="form-control input-width"
                            id="mail"
                            type="text"
                        />
                        <div
                            *ngIf="hasFeedbackError('MAIL_FORMAT')"
                            class="invalid-feedback"
                            translate="errors.patient.MAIL_FORMAT"
                        ></div>
                    </div>
                    <div class="flex-column p-2">
                        <input
                            (onAddressChange)="handleAddressChange($event)"
                            [(ngModel)]="patient.address.completeAddress"
                            [class.is-invalid]="
                                hasError('address.completeAddress')
                            "
                            [placeholder]="
                                'inclusion.contact.addressNotRequired'
                                    | translate
                            "
                            autocomplete="off"
                            class="form-control input-width"
                            ngx-google-places-autocomplete
                            type="search"
                        />
                    </div>
                </div>
            </div>
            <div class="form-section">
                <div class="form-section-title">
                    <app-message class="form-section-icon"></app-message>
                    <span
                        translate="inclusion.inclusionMode.cardio.comment"
                    ></span>
                </div>
                <div class="p-2">
                    <textarea
                        class="form-control input-width comment-height"
                        id="comment"
                        [placeholder]="
                            'inclusion.commentPlaceholder' | translate
                        "
                        [(ngModel)]="comment"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="form-footer">
        <app-loading-button
            [width100]="true"
            size="small"
            [disabled]="includePatientLoading$ | async"
            [loading]="includePatientLoading$ | async"
            (click)="includePatient()"
            classes="btn btn-primary btn-next mt-2 loading-btn fw-bold"
        >
            {{ 'inclusion.save' | translate }}
        </app-loading-button>
    </div>
</div>
<ng-template #template>
    <div class="modal-body criteria d-flex flex-row">
        <div class="criteria-bull">
            <li translate="inclusion.inclusionMode.cardio.criteria1"></li>
            <div translate="or"></div>
            <li translate="inclusion.inclusionMode.cardio.criteria2"></li>
        </div>
    </div>
</ng-template>
