<button
    [attr.disabled]="
        ((disabled | async) !== false && (disabled | async)) ||
        (loading | async)
            ? ''
            : null
    "
    [attr.class]="classes"
    [ngClass]="{
        secondary: color === 'secondary',
        small: size === 'small',
        'width-100': width100,
        uppercased: uppercased
    }"
>
    <div
        class="loader-dual-ring"
        *ngIf="loading | async"
        [ngClass]="{
            'icon-color': color === 'secondary' || iconColor === 'secondary'
        }"
    ></div>
    <ng-content></ng-content>
</button>
