export const environment = {
    production: true,
    authApiUrl: 'https://auth.api.preprod.1minutepourmoncoeur.fr',
    apiUrl: 'https://api.preprod.1minutepourmoncoeur.fr/1.0',
    pmpApiUrl: 'https://pmp.api.preprod.1minutepourmoncoeur.fr/',
    pmpSockets: 'wss://pmp.api.preprod.1minutepourmoncoeur.fr/',
    authfrontURL: 'https://auth.preprod.1minutepourmoncoeur.fr',
    psp: 'https://preprod.1minutepourmoncoeur.fr',
    psa: 'https://alerts.preprod.1minutepourmoncoeur.fr',
    newcardUrl: 'https://(alerts.)?preprod.1minutepourmoncoeur.fr.*',
    cookieDomain: '.1minutepourmoncoeur.fr',
    insiConsult: 'https://ins.preprod.1minutepourmoncoeur.fr',
    tutoCardio:
        'http://26570142.hs-sites-eu1.com/tutoriels-1-minute-pour-mon-c%C5%93ur',
    tutoNephro:
        'http://26570142.hs-sites-eu1.com/tutoriels-1-minute-pour-mes-reins',
};
