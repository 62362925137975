import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-loading-button',
    templateUrl: './loading-button.component.html',
    styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent {
    #loading = of(false);
    get loading(): Observable<boolean> {
        return this.#loading;
    }
    @Input() set loading(value: boolean | Observable<boolean>) {
        if (typeof value === 'boolean') {
            this.#loading = of(value);
        } else {
            this.#loading = value;
        }
    }
    #disabled = of(false);
    get disabled(): Observable<boolean> {
        return this.#disabled;
    }
    @Input() set disabled(value: boolean | Observable<boolean>) {
        if (typeof value === 'boolean') {
            this.#disabled = of(value);
        } else {
            this.#disabled = value;
        }
    }
    @Input() classes?: string;
    @Input() color?: 'secondary';
    @Input() iconColor?: 'secondary';
    @Input() size?: 'small';
    @Input() width100 = false;
    @Input() uppercased = true;
}
